import React from 'react'
import { graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

// Components
import {
  TitleDefault,
  TextLink,
  ButtonDefault,
  BannerDefault,
  ButtonAlt,
  ContentDefault,
  BoxDefault,
} from 'components/Elements'
import Layout from 'components/Layout'
import ParseContent from 'components/ParseContent'

import 'styles/templates/CopperAlloysPage.scss'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      content
      acf {
        banner {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                fixed(width: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`

function CopperAlloysPage({
  data: { page : { title, content, acf } },
  pageContext: {
    breadcrumb: { crumbs },
  },
}) {
  return (
    <Layout>
      <div className="copper-template">
        <section className="heroimage position-relative d-flex">
          <div className="container context">
            <div className="row py-4">
              <div className="col-lg-6">
                <TitleDefault className="mb-3 color-text-light">
                  {title}
                </TitleDefault>
                <div className="line mb-4" />
                <Breadcrumb
                  crumbs={crumbs}
                  crumbSeparator=">"
                  crumbLabel={title}
                />
              </div>
              <div className="col-lg-6" />
            </div>
          </div>
          <div className="heroimage-image row position-absolute">
            <div className="col-12 col-lg-5 fill" />
            <div className="heroimage-image-container col-lg-7 p-0">
              <BackgroundImage
                className="bgimage"
                fluid={
                  acf.banner.image.localFile
                    .childImageSharp.fluid
                }
              />
            </div>
          </div>
        </section>
        <section>
          <div className="container py-5">
            <div className="col-12">
              <ParseContent content={content} />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default CopperAlloysPage
